import React from 'react';
import { IonSpinner } from '@ionic/react';
import './LoadingSpinner.css'; // Import the custom CSS for styling
import logo from '../resources/logo_sks_zwart.png'



const LoadingSpinner: React.FC = () => {
    return (
        <div className="loading-spinner-with-logo">
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <IonSpinner name="crescent" color={'blue'} />
        </div>
    );
};

export default LoadingSpinner;
