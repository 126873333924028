import React, { useState } from 'react';
import { IonItem, IonLabel, IonInput } from '@ionic/react';
import './CurrencyInput.css';

interface CurrencyInputProps {
    value?: number;
    onValueChange: (value: number) => void;
    placeholder?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
                                                         value = 0,
                                                         onValueChange,
                                                         placeholder = 'Enter amount',
                                                     }) => {
    const [inputValue, setInputValue] = useState<number>(value);

    const handleInputChange = (e: CustomEvent) => {
        const rawValue = e.detail.value?.replace(/[^\d.]/g, '');
        const numericValue = parseFloat(rawValue || '0');
        setInputValue(numericValue);
        onValueChange(numericValue);
    };

    return (
        <IonItem className="currency-input-item">
            <IonInput
                type="text"
                value={inputValue}
                placeholder={placeholder}
                onIonInput={handleInputChange}
                inputmode="decimal"
                className="currency-input"
                color={'dark'}
            />
        </IonItem>
    );
};

export default CurrencyInput;
