import React, {useEffect, useState} from 'react';
import {IonReorder, IonReorderGroup, IonItem, IonLabel, IonList, IonButton} from '@ionic/react';
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";

interface ReorderPlayerProps {
    lobbyId: any;
}
export const ReorderPlayers: React.FC<ReorderPlayerProps> = ({lobbyId}) => {
    const [players, setPlayers] = useState<any[]>([]);
    const axios = createAxiosInstance();
    const showToast = useToast();

    const doReorder = (event: CustomEvent) => {
        const { detail } = event;
        if (detail.from !== detail.to) {
            const updatedPlayers = [...players];
            const [reorderedItem] = updatedPlayers.splice(detail.from, 1);
            updatedPlayers.splice(detail.to, 0, reorderedItem);

            setPlayers(updatedPlayers);

            detail.complete();
        } else {
            detail.complete();
        }
    };

    const saveOrder = async () => {
        // Assuming the backend expects an array of player IDs for the new order
        const playerIds = players.map(player => player._id);

        try {
            const response = await axios.patch(`/dertigen/${lobbyId}/lobby-state/players-order`, {
                newOrder: playerIds
            });
            // If the save operation is successful, you might want to inform the user
            showToast('De nieuwe volgorde is opgeslagen.', 'success');
            console.log('New order saved:', response.data);
        } catch (error) {
            // Handle any errors that occur during the save operation
            showToast('Er is een fout opgetreden bij het opslaan van de nieuwe volgorde.', 'danger');
            console.error('Error saving new order:', error);
        }
    };


    useEffect(() => {
        // Here you would fetch the players from your backend
        // and set them using setPlayers

        const fetchPlayers = async () => {
            try {
                await axios.get(`/dertigen/${lobbyId}/lobby-state/players-order`)
                    .then(res => {
                        setPlayers(res.data);
                    })
                    .catch(err => {
                        console.error('Error fetching players:', err);
                        showToast('Er is iets mis gegaan', "danger")
                    });

            } catch (err) {
                console.error('Error fetching players:', err);
            }
        }

        fetchPlayers();
    }, []);

    return (
        <>
            <IonList>
                <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                    {players.map((player) => (
                        <IonItem key={player?._id}>
                            <IonLabel>{player?.name}</IonLabel>
                            <IonReorder slot="end"/>
                        </IonItem>
                    ))}
                </IonReorderGroup>
            </IonList>
            <IonButton onClick={saveOrder} expand="block">Volgorde opslaan</IonButton>
        </>
    );
};

export default ReorderPlayers;
