import {IonButton, IonContent, IonHeader, IonInput, IonLoading, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import createAxiosInstance from '../services/AxiosInstance';

import {Auth} from '../Auth';
import {useToast} from "../Utils/useToasts";

const auth = new Auth();

const Register: React.FC = () => {
    const history = useHistory();
    const [busy, setBusy] = useState<boolean>(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const axios = createAxiosInstance();
    const showToasts = useToast();

    useEffect(() => {
        // Check the login status as soon as the component is rendered
        if (auth.isJWTValid()) {
            history.push('/');
        }
    }, []);

    async function login() {
        try {
            setBusy(true);
            await performRegister(username, password, passwordConfirm);
            setBusy(false);
        } catch (e) {
            console.error(e)
        }
    }

    const performRegister = async (username: string, password: string, passwordConfirm: string) => {
        if(password !== passwordConfirm) {
            showToasts("Wachtwoorden komen niet overeen!", 'danger')
            return
        }
        if (username.length === 0 || password.length === 0 || passwordConfirm.length === 0) {
            showToasts("Niet alle velden ingevuld", "danger")
            return
        }
        if (username.length < 4 ) {
            showToasts('Gebruikers naam te kort', 'danger')
            return
        }
        if (password.length < 8 || passwordConfirm.length < 8) {
            showToasts('Wachtwoord te kort', 'danger')
            return
        }
        // Replace this with a call to your backend to log the user in
        const registerBody = {
            username: username,
            password: password
        }
        axios.post(`/auth/register`, registerBody)
            .then(res => {
                if (res.status === 201) {
                    showToasts('Succesvol geregistreerd!', 'success')
                    localStorage.setItem('jwt', res.data.accessToken);
                    history.push('/welkom')
                }
            })
            .catch(err => {
                console.error(err);
                showToasts('Registratie onsuccesvol', 'danger')
            });
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Registreer</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonLoading message="Please wait.." duration={0} isOpen={busy}/>
            <IonContent className="ion-padding">
                <IonInput
                    placeholder="Gebruikersnaam"
                    onIonInput={(e: any) => setUsername(e.target.value)}
                />
                <IonInput
                    type="password"
                    placeholder="Wachtwoord"
                    onIonInput={(e: any) => setPassword(e.target.value)}
                />

                <IonInput
                    type="password"
                    placeholder="Bevestig wachtwoord"
                    onIonInput={(e: any) => setPasswordConfirm(e.target.value)}
                />
                <IonButton onClick={login}>Registreer</IonButton>
                <p>
                    Al een account? <a href="/login">Login</a>
                </p>
            </IonContent>
        </IonPage>
    );
}

export default Register;
