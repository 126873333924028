import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonItem, IonLabel,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";
import './DrinkStripesPopOver.css';
import LoadingSpinner from "./LoadingSpinner";

interface DrinkStripesPopOverProps {
    isOpen: boolean;
    onClose: () => void;
    playerId: any;
    lobbyId: any;
}

const DrinkStripesPopOver: React.FC<DrinkStripesPopOverProps> = ({
                                                                     isOpen,
                                                                     onClose,
                                                                     playerId,
                                                                     lobbyId
                                                                 }) => {
    const axios = createAxiosInstance();
    const showToasts = useToast();
    const [player, setPlayer] = useState<any>({});
    const [players, setPlayers] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPlayerData = async () => {
            setLoading(true)
            try {
                await axios.get(`/dertigen/${lobbyId}/${playerId}`)
                    .then(res => {
                        setPlayer(res.data);
                        setPlayers(res.data.players);
                        setLoading(false)
                    }).catch(error => {
                        console.error(error);
                        setLoading(false)
                    });
            } catch (error) {
                console.error(error);
            }
        };

        if (isOpen) {
            fetchPlayerData();
        }
    }, [isOpen, playerId, lobbyId]);

    const drankSomething = (amount: number) => {
        if (selectedOption === '') {
            axios.patch(`/dertigen/move/${lobbyId}/${playerId}`,
                {
                    deltaStripes: -amount,
                    deltaGulps: amount
                })
                .then(res => {
                    setPlayer(res.data)
                    showToasts(`Je hebt ${amount} strepen gedronken`, "success")
                }).catch(err => {
                console.log(err.response.status)
                if (err.response.status === 405) {
                    showToasts('Je hebt geen toestemming', "danger")
                } else {
                    showToasts('Er is iets mis gegaan', "danger")
                }
            })
        } else {
            axios.patch(`dertigen/help-friend/${lobbyId}/${playerId}/${selectedOption}`,
                {amountOfGulps: Number(amount)})
                .then(res => {
                    showToasts(`Een friend heeft geholpen met ${amount} slokjes!`, "success")
                    setPlayer(res.data)
                    setSelectedOption('')
                }).catch(err => {
                if (err.response.status === 405) {
                    showToasts('Je hebt geen toestemming', "danger")
                } else {
                    showToasts('Er is iets mis gegaan', "danger")
                }
            });
        }
    }

    const handleSelectChange = (event: CustomEvent) => {
        setSelectedOption(event.detail.value);
    };

    const handleCheckboxChange = (event: CustomEvent) => {
        if (!event.detail.checked) {
            setSelectedOption('');
        }
    };

    const handleClose = () => {
        onClose(); // Call the original onClose function prop
        setSelectedOption(''); // Reset the selectedOption state
    };

    return (
        <IonPopover isOpen={isOpen} onDidDismiss={handleClose} className={'popover-layout'}>
            {loading ? <LoadingSpinner/>
                :
                (
                    <>
                        <div className={'drink-container'}>
                            <IonLabel class="ion-text-center">
                                <h1>
                                    {player?.player}: {player?.stripes} strepen
                                </h1>
                            </IonLabel>
                            <div className="add-stripes-grid">
                                <div className="custom-row ion-color-success">
                                    <div className="custom-col ion-text-center">
                                        <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`}
                                                   onClick={() => drankSomething(10)}>Halve bak</IonButton>
                                    </div>
                                    <div className="custom-col ion-text-center">
                                        <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`}
                                                   onClick={() => drankSomething(20)}>Hele bak</IonButton>
                                    </div>
                                </div>
                                <div className="custom-row">
                                    <div className="custom-col ion-text-center">
                                        <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`}
                                                   onClick={() => drankSomething(25)}>Duikboot</IonButton>
                                    </div>
                                    <div className="custom-col ion-text-center">
                                        <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`}
                                                   onClick={() => drankSomething(30)}>Rietadt</IonButton>
                                    </div>
                                </div>
                                <div className="custom-item">
                                    <IonCheckbox slot="start" checked={selectedOption !== ''}
                                                 onIonChange={handleCheckboxChange}
                                                 disabled={selectedOption === ''} />
                                    <IonSelect placeholder="Wie drinkt er voor deze persoon?" value={selectedOption}
                                               interface={'action-sheet'}
                                               onIonChange={handleSelectChange} fill={'outline'}
                                               className="custom-select"
                                               >
                                        {players.filter((value: any) => value.id !== player.playerId).map((value: any, key: any) => (
                                            <IonSelectOption className='ion-select-option-class custom-select-option' key={key}
                                                             value={value.id}>
                                                {value.name}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </IonPopover>
    );
};

export default DrinkStripesPopOver;
