import React, { useEffect, useState } from "react";
import createAxiosInstance from "../services/AxiosInstance";
import { useToast } from "../Utils/useToasts";
import MqttBrightnessComponent from "../components/MqttBrightnessComponent";
import {
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonHeader,
    IonItem,
    IonMenuButton,
    IonPage,
    IonRange,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";

const MqttController: React.FC = () => {
    const [barBrightness, setBarBrightness] = useState<number>(0);
    const [leftWallBrightness, setLeftWallBrightness] = useState<number>(0);
    const [rightWallBrightness, setRightWallBrightness] = useState<number>(0);
    const axios = createAxiosInstance();
    const showToasts = useToast();
    const [red, setRed] = useState(0);
    const [green, setGreen] = useState(0);
    const [blue, setBlue] = useState(0);

    const handleBarLightingChanged = async (value: number) => {
        try {
            setBarBrightness(value);
            await axios.post("/mqtt/send-bar-lighting", { brightness: value });
        } catch (err) {
            console.error("Error updating bar lighting:", err);
            showToasts("Er is iets misgegaan met het updaten van de verlichting", "danger");
        }
    };

    const handleLeftWallLightingChanged = async (value: number) => {
        try {
            setLeftWallBrightness(value);
            await axios.post("/mqtt/send-left-wall-lighting", { brightness: value });
        } catch (err) {
            console.error("Error updating left wall lighting:", err);
            showToasts("Er is iets misgegaan met het updaten van de verlichting", "danger");
        }
    };

    const handleRightWallLightingChanged = async (value: number) => {
        try {
            setRightWallBrightness(value);
            await axios.post("/mqtt/send-right-wall-lighting", { brightness: value });
        } catch (err) {
            console.error("Error updating right wall lighting:", err);
            showToasts("Er is iets misgegaan met het updaten van de verlichting", "danger");
        }
    };

    const handleSliderChange = async (color: string, value: number) => {
        let newRed = red;
        let newGreen = green;
        let newBlue = blue;

        switch (color) {
            case 'red':
                newRed = value;
                setRed(newRed);
                break;
            case 'green':
                newGreen = value;
                setGreen(newGreen);
                break;
            case 'blue':
                newBlue = value;
                setBlue(newBlue);
                break;
            default:
                break;
        }

        try {
            await axios.post("/mqtt/send-ledstrip", { red: newRed / 2.55, green: newGreen / 2.55, blue: newBlue / 2.55 });
        } catch (err) {
            console.error("Error updating LED strip:", err);
            showToasts("Er is iets misgegaan met het updaten van de verlichting", "danger");
        }
    };

    const fetchCurrentLedStripSettings = async () => {
        try {
            const res = await axios.get("/mqtt/ledstrip-settings");
            const red = res.data.ledStripStatus.integer1;
            const green = res.data.ledStripStatus.integer2;
            const blue = res.data.ledStripStatus.integer3;

            setRed(Math.round(red * 2.55));
            setGreen(Math.round(green * 2.55));
            setBlue(Math.round(blue * 2.55));
        } catch (err) {
            console.error("Error fetching LED strip settings:", err);
            showToasts("Er is iets misgegaan met het ophalen van de instellingen", "danger");
        }
    };

    const fetchCurrentBarLightSettings = async () => {
        try {
            const res = await axios.get("/mqtt/bar-lighting-status");
            setBarBrightness(res.data.brightness);
        } catch (err) {
            console.error("Error fetching bar lighting settings:", err);
            showToasts("Er is iets misgegaan met het ophalen van de instellingen", "danger");
        }
    };

    const fetchCurrentLeftWallLightSettings = async () => {
        try {
            const res = await axios.get("/mqtt/left-wall-lighting-status");
            setLeftWallBrightness(res.data.brightness);
        } catch (err) {
            console.error("Error fetching left wall lighting settings:", err);
            showToasts("Er is iets misgegaan met het ophalen van de instellingen", "danger");
        }
    };

    const fetchCurrentRightWallLightSettings = async () => {
        try {
            const res = await axios.get("/mqtt/right-wall-lighting-status");
            setRightWallBrightness(res.data.brightness);
        } catch (err) {
            console.error("Error fetching right wall lighting settings:", err);
            showToasts("Er is iets misgegaan met het ophalen van de instellingen", "danger");
        }
    };

    useEffect(() => {
        fetchCurrentBarLightSettings();
        fetchCurrentLeftWallLightSettings();
        fetchCurrentRightWallLightSettings();
        fetchCurrentLedStripSettings();
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Verlichting besturen</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <MqttBrightnessComponent initialValue={barBrightness} title={"Bar verlichting"} onValueChange={handleBarLightingChanged}></MqttBrightnessComponent>
                <MqttBrightnessComponent initialValue={leftWallBrightness} title={"Kolf links"} onValueChange={handleLeftWallLightingChanged}></MqttBrightnessComponent>
                <MqttBrightnessComponent initialValue={rightWallBrightness} title={"Kolf rechts"} onValueChange={handleRightWallLightingChanged}></MqttBrightnessComponent>

                <IonCard>
                    <IonCardHeader>
                        <IonTitle>
                            Ledstrips
                        </IonTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonRange min={0} max={255} value={red} onIonChange={(e) => handleSliderChange('red', e.detail.value as number)}>
                                <div slot="label">Rood</div>
                            </IonRange>
                        </IonItem>
                        <IonItem>
                            <IonRange min={0} max={255} value={green} onIonChange={(e) => handleSliderChange('green', e.detail.value as number)}>
                                <div slot="label">Groen</div>
                            </IonRange>
                        </IonItem>
                        <IonItem>
                            <IonRange min={0} max={255} value={blue} onIonChange={(e) => handleSliderChange('blue', e.detail.value as number)}>
                                <div slot="label">Blauw</div>
                            </IonRange>
                        </IonItem>
                        <div style={{ margin: '20px 0', textAlign: 'center' }}>
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundColor: `rgb(${red},${green},${blue})`,
                                    margin: '0 auto',
                                }}
                            ></div>
                            <IonText>
                                <h4>RGB: ({red}, {green}, {blue})</h4>
                            </IonText>
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
}

export default MqttController;
