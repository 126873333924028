import React, { useEffect, useRef } from 'react';
import Chart, { ChartTypeRegistry } from 'chart.js/auto';

interface PercentageChartProps {
    percentage: number;
}

const PercentageChart: React.FC<PercentageChartProps> = ({ percentage }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if (chartRef.current) {
            const canvas = chartRef.current;
            const context = canvas.getContext('2d');

            if (context) {
                const chart = new Chart(context, {
                    type: 'doughnut',
                    data: {
                        labels: ['Percentage'],
                        datasets: [
                            {
                                data: [percentage, 100 - percentage],
                                backgroundColor: ['#0763a8', '#eaeaea'],
                                borderWidth: 0,
                            },
                        ],
                    },
                    options: {
                        cutout: '70%',
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: false,
                            },
                        },
                    },
                });

                return () => {
                    chart.destroy();
                };
            }
        }
    }, [percentage]);

    return <canvas ref={chartRef}></canvas>;
};

export default PercentageChart;
