import React, { useState, useEffect } from 'react';
import { IonItem, IonLabel, IonInput, IonText } from '@ionic/react';

interface EmailInputProps {
    onEmailChange: (email: string, isValid: boolean) => void;
    initialEmail?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({ onEmailChange, initialEmail }) => {
    const [email, setEmail] = useState(initialEmail || '');
    const [error, setError] = useState('');

    useEffect(() => {
        setEmail(initialEmail || '');
        setError(emailRegex.test(initialEmail || '') ? '' : 'Voer een geldig e-mailadres in.');
        onEmailChange(initialEmail || '', emailRegex.test(initialEmail || ''));
    }, [initialEmail, onEmailChange]);


    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleEmailChange = (event: CustomEvent) => {
        const value = event.detail.value!;
        setEmail(value);
        const isValid = emailRegex.test(value);
        onEmailChange(value, isValid);
        setError(isValid ? '' : 'Voer een geldig e-mailadres in.');
    };

    return (
        <>
            <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput type="email" value={email} onIonInput={handleEmailChange} />
            </IonItem>
            {error && (
                <IonText color="danger" className="ion-padding-start">
                    {error}
                </IonText>
            )}
        </>
    );
};

export default EmailInput;
