import {
    IonBackButton, IonButtons,
    IonContent,
    IonHeader,
    IonInfiniteScroll,
    IonInfiniteScrollContent, IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './Ranglijsten.css';
import React, {useEffect, useState} from "react";
import createAxiosInstance from "../services/AxiosInstance";
import {Transaction} from "../model/Transaction";
import TransactionCard from "../components/TransactionCard";

const Transactions: React.FC = () => {

    const [transactions, setTransactions] = useState<Transaction[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const axios = createAxiosInstance();

    useEffect(() => {
        fetchTransactions(currentPage);
    }, []);

    const fetchTransactions = async (page: number) => {
        try {
            const response = await axios.get(`/transaction?limit=10&offset=${(page - 1) * 10}`);
            const newTransactions = response.data;

            console.log(newTransactions)
            setTransactions((prevTransactions: Transaction[] | undefined) => {
                return prevTransactions ? [...prevTransactions, ...newTransactions] : newTransactions;
            });

            setCurrentPage((prevPage: number) => prevPage + 1);
            if (newTransactions !== undefined && newTransactions.length < 10) {
                setHasMore(false);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const loadMoreTransactions = async (event: CustomEvent<void>) => {
        await fetchTransactions(currentPage);
        (event.target as HTMLIonInfiniteScrollElement).complete();
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Transacties</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                {transactions !== undefined && transactions.map((value: Transaction, key: any) => (
                    <TransactionCard key={key} amount={value.playerDelta} description={value.remark}
                                     date={value.transDate} isTransaction={value.isTransaction}/>
                ))}
                <IonInfiniteScroll threshold="100px" onIonInfinite={loadMoreTransactions}>
                    <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Laden meer transacties...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    )
};

export default Transactions;
