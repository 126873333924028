import React from 'react';
import {IonCard, IonCardContent, IonCardTitle, IonCardSubtitle, IonIcon} from '@ionic/react';
import './TransactionCard.css';
import {repeatOutline, fingerPrintOutline} from "ionicons/icons";
import CurrencyDisplay from "./CurrencyDisplay";

// Define a type for the component props
interface TransactionCardProps {
    amount: number;
    description: string;
    date: Date;
    isTransaction: boolean;
}

const TransactionCard: React.FC<TransactionCardProps> = ({amount, description, date, isTransaction}) => {
    return (
        <IonCard>
            <IonCardContent>
                <div className="card-content">
                    <div className="description-date">
                        <div className="left-container">
                            {isTransaction !== null && isTransaction ? <IonIcon className={'transaction-icon'} icon={repeatOutline}/> : <IonIcon className={'checkin-icon'} icon={fingerPrintOutline}/>}
                            <div className="column-container">
                                <IonCardTitle class={'description-title'}>{description}</IonCardTitle>
                                <IonCardSubtitle class={'date-title'}>{date}</IonCardSubtitle>
                            </div>
                        </div>
                    </div>
                    <CurrencyDisplay amount={amount}/>
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default TransactionCard;
