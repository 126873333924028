import React, {useEffect, useState} from "react";
import {
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon, IonMenuButton, IonModal,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import './DertigenStatutes.css';
import createAxiosInstance from "../services/AxiosInstance";
import {Statute} from "../model/Statute";
import {Paragraph} from "../model/Paragraph";
import LoadingSpinner from "../components/LoadingSpinner";
import {User} from "../model/User";
import {addCircle, pencilOutline} from "ionicons/icons";
import DertigenStatutesAdd from "./DertigenStatutesAdd";
import {stat} from "fs";


const DertigenStatutes: React.FC = () => {
    const axios = createAxiosInstance();
    const [statutes, setStatutes] = useState<Statute[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User>()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedStatute, setSelectedStatute] = useState<Statute | undefined>(undefined);

    const fetchPeronalData = () => {
        axios.get('/player/personal-data/header')
            .then(res => {
                setUser(res.data)
            })
            .catch(error => {
                console.error(error);
            })
    }


    const fetchStatutes = async () => {
        await axios.get("/statutes")
            .then(res => {
                setStatutes(res.data.statutes); // Assuming the response data is in res.data.statutes
                setLoading(false);
            }).catch(err => {
                console.error(err);
            });
    };

    useEffect(() => {
        fetchStatutes();
        fetchPeronalData()
    }, []);

    const closeModal = async () => {
        await fetchStatutes().then(() => setShowModal(false))
    }

    const handleEditClick = (statute: Statute) => {
        setSelectedStatute(statute);
        setShowModal(true);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Dertigen Statuten</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonModal isOpen={showModal} onDidDismiss={() => closeModal()}>
                    <DertigenStatutesAdd
                        closeModal={() => setShowModal(false)}
                        existingStatute={selectedStatute}
                        isEditing={!!selectedStatute}
                    />
                </IonModal>
                {loading ? (
                    <LoadingSpinner/>
                ) : (
                    statutes.map((statute: Statute, index: number) => (
                        <IonCard className={'statute-card'} key={index}>
                            <IonCardHeader>
                                <IonCardTitle className={'statute-title'}>{statute.chapterNumber}. {statute.title}</IonCardTitle>
                            </IonCardHeader>

                            {statute.content && (
                                <>
                                    <hr/>
                                    <p className={"content-description"}>{statute.content}</p>
                                </>
                            )}
                            <hr/>
                            <IonCardContent class={'statute-card-content'}>
                                {statute.paragraphs.map((paragraph: Paragraph, pIndex: number) => (
                                    <React.Fragment key={pIndex}>
                                        {pIndex !== 0 && <hr/>}
                                        <p className={"rule-description"}>{paragraph.paragraphNumber}. {paragraph.content}</p>
                                    </React.Fragment>
                                ))}
                            </IonCardContent>
                            {user?.roles.includes("rule-boss") && (
                                <IonIcon
                                    icon={pencilOutline}
                                    style={{
                                        background: 'black',
                                        color: 'white',
                                        position: 'absolute',
                                        top: '15px',
                                        right: '15px',
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        cursor: 'pointer',
                                        zIndex: 5,
                                    }}
                                    onClick={() => handleEditClick(statute)}
                                />
                            )}
                        </IonCard>
                    ))
                )}

                {user?.roles?.includes("rule-boss") && (
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={() => setShowModal(true)}>
                        <IonIcon icon={addCircle}/>
                    </IonFabButton>
                </IonFab>
                )}

            </IonContent>
        </IonPage>
    );
}

export default DertigenStatutes;
